<template>
  <div class="m-view-channel">
    <PopTop
      @open="showLoginDialog = true"
      class="channel-head"
      logo=""
      :black="true"
      :showLogo="false"
      :showLoginBtn="!!$utils.isMobile().mobile"
    ></PopTop>

    <pop-login
      :show="showLoginDialog"
      @close="showLoginDialog = false"
    ></pop-login>
    <div class="channel-container">
      <div class="banner">
        <img
          class="cursor-pointer"
          :src="item.img"
          v-for="item in channel.advertisement_position"
          @click="handleJumpBanner(item)"
        />
      </div>

      <div class="channel-describe">
        <van-swipe :autoplay="3000" class="channel-swiper">
          <van-swipe-item
            v-for="(image, index) in this.channel.image"
            :key="index"
            @click="handleJumpBanner(image)"
          >
            <img v-lazy="image.img" />
          </van-swipe-item>
        </van-swipe>
        <div class="channel-describe-text">
          <div class="channel-describe-title">
            <img :src="this.channel.logo" class="channel-logo" />
            <p class="channel-name">{{ channel.name }}</p>
          </div>
          <div class="describe">
            <div v-html="channel.describe"></div>
          </div>
        </div>
        <img
          :src="this.channel.image?.length && this.channel.image[0].img"
          class="boxshadow-image"
        />
      </div>

      <div class="channel-button-group">
        <van-button
          v-for="item in [
            { text: '全部活动', status: 0 },
            { text: '进行中', status: 2 },
            { text: '待开始', status: 1 },
            { text: '已结束', status: 3 },
          ]"
          :key="item.status"
          :class="{ active: searchOption.status == item.status }"
          @click="searchOption.status = item.status"
          >{{ item.text }}</van-button
        >
      </div>

      <div class="active-container">
        <active-card
          @click="handleJump(item.activity.id)"
          class="active-card"
          v-for="item in activeList"
          :src="item.activity.cover"
          :title="item.activity.title"
          :key="item.id"
          :name="item.nickname"
          :start_time="item.activity.start_time"
          :end_time="item.activity.end_time"
          :type="item.activity.status_txt"
        ></active-card>
      </div>
      <van-pagination
        v-model="searchOption.page"
        :items-per-page="searchOption.per_page"
        :total-items="total"
        :show-page-size="5"
        v-show="total"
      >
        <template #prev-text>
          <van-button>
            <van-icon name="arrow-left" />
          </van-button>
        </template>
        <template #next-text>
          <van-button>
            <van-icon name="arrow" />
          </van-button>
        </template>
        <template #page="{ text }">
          <van-button :class="{ active: searchOption.page == text }">
            {{ text }}
          </van-button>
        </template>
      </van-pagination>

      <div class="channel-company" v-if="channel.relation_unit">
        <p class="title">关联单位</p>
        <p class="title-en">Related units</p>

        <div class="company-list">
          <div class="company-card" v-for="item in channel.relation_unit">
            <img :src="item.img" @click="handleJumpBanner(item)" />
          </div>
        </div>
      </div>

      <div
        @click="handleHome"
        class="bottom-text"
        v-if="channel.show_sct_technology"
      >
        技术支持:三川田
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Button, Pagination } from "vant";
import activeCard from "@/components/common/CompanyCard.vue";
import PopTop from "@/components/PopTop.vue";
import { wxShare } from "@/assets/js/wechatAuth.js";
import PopLogin from "@/components/PopLogin.vue";
// import { wxShare } from "@/assets/js/wechat.js";
import { routesData, routesModuleData, routesConvert } from "@/router/index";
export default {
  components: {
    [Button.name]: Button,
    [Pagination.name]: Pagination,
    activeCard,
    PopTop,
    PopLogin,
  },
  data() {
    return {
      channel: {},
      searchOption: {
        page: 1,
        per_page: 10,
        status: 0,
        channel_id: "",
      },
      activeList: [],
      total: 0,
      loginNavShow: false,
      showLoginDialog: false,
    };
  },
  created() {
    this.searchOption.channel_id = this.$route.query.id;
    this.fetchActiveData();
    this.fetchData();
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    fetchActiveData() {
      this.$http
        .get("/channel-manage/channel-activity", this.searchOption)
        .then(({ data }) => {
          this.activeList = data.data;
          this.total = data.total;
        });
    },
    async fetchData() {
      this.$http
        .get(`/channel-manage/detail?id=${this.$route.query.id}`)
        .then(({ data }) => {
          wxShare({
            title: data.name,
            link: window.location.href,
            desc: data.name,
            imgUrl: data.logo,
          });
          this.channel = data;
          document.title = data.name;
        });
    },
    deleteRow({ id }) {
      this.$http.post("/channel-manage/del", { id }).then(() => {
        this.fetchData();
      });
    },
    editRow({ id }) {
      this.$router.push({
        path: "/stage/channel/edit",
        query: { id },
      });
    },
    handleJump(id) {
      this.$router.push({
        path: "/stage/detail",
        query: { id },
      });
    },
    handleHome() {
      window.location.href =
        this.channel.jump_url || "https://meta.idea888.com/";
    },
    loginNavOpen() {
      // if (!this.$utils.isMobile().wechat) {
      this.loginNavShow = true;
      // }
    },
    goManage(route) {
      this.loginNavShow = false;
      const router = {
        name: routesConvert(routesModuleData.manage, true).name,
        query: {
          tab: route,
        },
      };
      if (
        this.$route.name != routesConvert(routesModuleData.manage, true).name
      ) {
        this.$router.push(router);
      } else {
        this.$emit("go", route);
      }
    },
    handleJumpBanner({ link }) {
      if (link) {
        //自动补全https
        if (link.includes("http")) {
          window.open(link);
        } else {
          window.open("https://" + link);
        }
      }
    },
  },
  watch: {
    "searchOption.page"(page) {
      this.fetchActiveData();
    },
    "searchOption.status"(page) {
      this.searchOption.page = 1;
      this.fetchActiveData();
    },
  },
};
</script>

<style lang="scss" scoped>
.m-view-channel {
  width: 100vw;
  background-image: url("../../assets/img/channel-view-bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;
  box-sizing: border-box;
  @media screen and (max-width: 750px) {
    background-image: url("../../assets/img/channel-view-bg-m.png");
  }
  * {
    box-sizing: border-box;
  }
  .channel-head {
    width: 100vw;
    height: 120px;
    background: linear-gradient(131deg, #410692 10%, #2e1daa 45%, #45bdff 99%);
    padding: 0 144px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    ::v-deep .top {
      top: 0 !important;
      position: relative;
      justify-content: flex-end;
    }

    .channel-head-right {
      display: flex;
      align-items: center;
      img {
        width: 80px;
        height: 80px;
      }
      span {
        font-size: 28px;
        font-weight: 350;
        color: #ffffff;
        line-height: 28px;
        margin-left: 14px;
      }
    }

    @media screen and (max-width: 750px) {
      height: 10.13vmin;
      .channel-head-right {
        img {
          width: 6.67vmin;
          height: 6.67vmin;
        }
        span {
          font-size: 2.4vmin;
          line-height: 1;
          margin-left: 1.2vmin;
        }
      }
    }

    @media (orientation: portrait) {
      .login {
        width: 717px;
        height: 266px;
        border-radius: 123px;
        span {
          font-size: 143px;
        }
      }
      .login-user {
        .user-avatar {
          width: 258px;
          height: 258px;
          margin-right: 46px;
        }
        img {
          width: 258px;
          height: 258px;
        }
        span {
          font-size: 92px;
          &.name {
            max-width: 600px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .login-nav {
          width: 1367px;
          height: 1500px;
          padding: 220px 0;
          line-height: 1500px;
          border-radius: 56px;
          right: 0;
          left: unset;
          &.active {
            top: 350px;
          }
          span {
            width: 100%;
            height: 271px;
            line-height: 271px;
            font-size: 184px;
            padding: 0 276px;
            &:first-child {
            }
          }
        }
      }
    }
  }
  .channel-container {
    width: 77.34375vw;
    padding-top: 160px;
    // overflow-x: hidden;
    margin: auto;
    box-sizing: border-box;
    padding-bottom: 180px;

    @media screen and (max-width: 750px) {
      width: 89.33vw;
      padding-top: calc(10.13vmin + 80px);
    }
  }

  .channel-describe {
    border-radius: 40px;
    margin-bottom: 120px;
    position: relative;
    .boxshadow-image {
      position: absolute;
      pointer-events: none;
      z-index: 0;
      bottom: 0;
      filter: blur(20px);
      width: 93%;
      height: 10%;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 20%;
      opacity: 0.4;
      @media screen and (max-width: 750px) {
        display: none;
      }
    }

    .channel-describe-text {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      height: fit-content;
      padding: 21px 0;
      position: relative;

      background-color: rgba(255, 255, 255, 0.65);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);

      border-radius: 0px 0px 40px 40px;
      padding-left: 110px;
      z-index: 1;

      display: flex;
      align-items: center;
      border-left: 1px solid rgba(0, 0, 0, 0.1);

      &::after {
        content: "";
        width: 100%;
        bottom: 0;
        transform: translate(0%, 0px);
        height: 1px;
        background-image: linear-gradient(
          to right,
          #fff 0%,
          rgba(0, 0, 0, 0.1) 3%,
          rgba(255, 255, 255, 0.8) 40%
        );
        position: absolute;
        left: 0;
      }

      @media screen and (max-width: 750px) {
        flex-direction: column;
        padding: 0 5.33vmin;
        padding-bottom: 5.07vmin;
      }

      .channel-describe-title {
        display: flex;
        align-items: center;
        width: 38%;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        height: 100%;
        flex-shrink: 0;
        .channel-logo {
          width: 150px;
          height: 150px;
          margin-right: 40px;
        }
        .channel-name {
          font-size: 48px;
          font-family: Source Han Sans-Bold, Source Han Sans;
          font-weight: 700;
          color: #333333;
          line-height: 1;
        }
        @media screen and (max-width: 750px) {
          width: 100%;
          padding-top: 3.73vmin;
          padding-bottom: 2.53vmin;
          margin-bottom: 3.47vmin;
          border: none;
          border-bottom: 1px solid rgba(173, 173, 173, 1);
          .channel-logo {
            width: 10vmin;
            height: 10vmin;
          }
          .channel-name {
            font-size: 4vmin;
          }
        }
      }
      .describe {
        height: 100%;
        padding-left: 74px;
        padding-right: 58px;
        overflow-y: auto;
        flex-grow: 1;
        ::v-deep p {
          font-size: 32px;
          font-family: Source Han Sans-Regular, Source Han Sans;
          font-weight: 400;
          color: #333333;
          line-height: 48px;
          text-align: left;
        }
        @media screen and (max-width: 750px) {
          padding: 0;
          width: 100%;
          ::v-deep p {
            font-size: 2.93vmin;
            line-height: 5.33vmin;
          }
        }
      }
    }
    .channel-swiper {
      margin-top: 40px;
      width: 100%;
      height: 1200px;
      border-radius: 40px 40px 0px 0px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }

      @media screen and (max-width: 750px) {
        // height: 36vmin;
        width: 100%;
        object-fit: contain;
        height: initial;
      }
    }

    @media screen and (max-width: 750px) {
      margin-bottom: 8vmin;
      border-radius: 2.67vmin;
      box-shadow: 0px 2vmin 2vmin 0.2vmin rgba(0, 0, 0, 0.1);
    }
  }

  .channel-button-group {
    display: flex;
    column-gap: 40px;
    margin-bottom: 122px;
    .van-button {
      width: fit-content;
      height: fit-content;
      background-color: #fff;
      border-radius: 43px 43px 43px 43px;
      font-size: 32px;
      font-family: Source Han Sans-Medium, Source Han Sans;
      font-weight: 500;
      line-height: 78px;
      color: rgba(102, 102, 102, 1);
      border: 1px solid #cccccc;
      padding: 0 1em;
    }
    .van-button.active {
      background: #496eec;
      color: #fff;
      border: 1px solid #496eec;
    }
    @media screen and (max-width: 750px) {
      display: flex;
      column-gap: 2.67vmin;
      .van-button {
        border-radius: 4.06vmin;
        font-size: 3.2vmin;
        line-height: 4.67vmin;
        padding: 1.33vmin 4.53vmin;
      }
      .van-button.active {
      }
    }
  }
  .active-container {
    display: flex;
    column-gap: 40px;
    width: 2970px;
    flex-wrap: wrap;
    .active-card {
      margin-bottom: 100px;
      box-shadow: 0px 0px 110px 0px rgba(173, 174, 198, 0.59);
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    @media screen and (max-width: 750px) {
      width: 100%;
      column-gap: initial;
      justify-content: space-between;
    }
  }
  .van-pagination {
    align-items: center;
    column-gap: 36px;
    justify-content: center;
    margin-bottom: 180px;
    @media screen and (max-width: 750px) {
      column-gap: 2vmin;
      margin-top: 2vmin;
    }
    ::v-deep li {
      flex-grow: 0;
      // width: 72px;
      // height: 72px;
      min-height: 72px;
      min-width: 72px;
      display: block;
      background: #e7e9ea;
      border: none;
      height: fit-content;
      background-color: initial;
      padding: 0;
      &::after {
        border: none;
      }
      .van-button {
        padding: 0;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        border: none;
        background-color: rgba(231, 233, 234, 1);
        font-size: 36px;
        font-family: DIN-Regular, DIN;
        font-weight: 500;
        color: #666666;
        line-height: 40px;
      }
      .van-button.active {
        background-color: rgba(73, 110, 236, 1);
        color: #fff;
      }
      @media screen and (max-width: 750px) {
        min-width: 6vmin;
        min-height: 6vmin;
        display: flex;
        align-items: center;
        justify-content: center;
        .van-button {
          width: 6vmin;
          height: 6vmin;
          font-size: 3vmin;
        }
      }
    }
  }

  .channel-company {
    .title {
      font-size: 60px;
      font-family: Source Han Sans-Bold, Source Han Sans;
      font-weight: 700;
      color: #333333;
      line-height: 56px;
      letter-spacing: 18px;
      text-align: center;
    }
    .title-en {
      font-size: 48px;
      font-family: DIN-Regular, DIN;
      font-weight: 500;
      color: #b0b0b0;
      line-height: 58px;
      letter-spacing: 8px;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 158px;
    }
    @media screen and (max-width: 750px) {
      .title {
        font-size: 4vmin;
        line-height: 3.73vmin;
        letter-spacing: 9px;
      }
      .title-en {
        font-size: 3.2vmin;
        line-height: 3.87vmin;
        letter-spacing: 4px;
        margin-bottom: 8vmin;
      }
    }
  }

  .company-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 28px;
    margin-bottom: 180px;
    .company-card {
      width: 690px;
      height: 234px;
      background-color: rgba(255, 255, 255, 0.5);
      img {
        width: 100%;
        height: 100%;
      }
    }
    @media screen and (max-width: 750px) {
      column-gap: initial;
      row-gap: 1.73vmin;
      justify-content: space-between;
      .company-card {
        width: 43.78vmin;
        height: 14.8vmin;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .bottom-text {
    font-size: 48px;
    font-family: DIN-Regular, DIN;
    font-weight: 500;
    color: #b0b0b0;
    line-height: 58px;
    letter-spacing: 8px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 158px;
    cursor: pointer;

    @media screen and (max-width: 750px) {
      font-size: 3.2vmin;
      line-height: 3.87vmin;
    }
  }

  .banner {
    display: flex;
    // justify-content: space-between;
    column-gap: 60px;
    img {
      width: 32%;
      height: 270px;
      border-radius: 20px;
    }
    @media screen and (max-width: 750px) {
      width: 100%;
      overflow-x: auto;
      margin-bottom: 4.8vmin;
      img {
        width: 64.27vw;
        height: 18vw;
        object-fit: cover;
      }
    }
  }
}
</style>
