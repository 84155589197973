<template>
  <div class="company-card-con" @click="handleClick">
    <img :src="src" alt="" srcset="" />
    <div class="con">
      <p class="title">{{ title }}</p>
      <p class="time">
        {{ timeFormat(start_time) + " - " + timeFormat(end_time) }}
      </p>
      <div class="info">
        <div class="name">
          <img class="icon" :src="$ossRes('/index/com-card-icon.png')" />
          <span>{{ name }}</span>
        </div>
        <span class="type type1">{{ type }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    start_time: {
      type: String,
      default: null,
    },
    end_time: {
      type: String,
      default: null,
    },
    time: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    // type_name: {
    //   type: String,
    //   default: null,
    // },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
    timeFormat(time) {
      // console.log("🚀 ~ file: CompanyCard.vue:68 ~ timeFormat ~ time:", time);
      // let date = new Date(time);
      // const y = date.getFullYear();
      // const m = String(date.getMonth() + 1); // 月份从0开始，所以要加1
      // const d = String(date.getDate());
      // const h = String(date.getHours());
      // const min = String(date.getMinutes());
      // return `${y}/${m}/${d} ${h}:${min}`;
      return time.split(":00")[0];
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.company-card-con {
  width: 712px;
  border-radius: 30px 30px 0 0;

  img {
    width: 100%;
    height: 422px;
    border-radius: 30px 30px 0 0;
  }

  .con {
    padding: 30px 40px 60px 40px;
    height: 260px;
    position: relative;

    .title {
      font-size: 40px;
      font-family: Source Han Sans-Regular, Source Han Sans;
      // font-weight: 600;
      color: #333333;
      margin-bottom: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .time {
      height: 42px;
      font-size: 35px;
      font-family: DIN-Regular, DIN;
      font-weight: 400;
      color: #666666;
      line-height: 40px;
      white-space: nowrap;
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      left: 40px;
      right: 40px;
      bottom: 60px;

      .name {
        display: flex;
        align-items: center;

        img {
          width: 48px;
          height: 48px;
          margin-right: 20px;
        }

        span {
          font-size: 32px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 350;
          color: #666666;
          line-height: 48px;
        }
      }

      .type {
        height: 42px;
        font-size: 32px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        line-height: 48px;
      }

      .type1 {
        color: #ea3535;
      }

      //   .type2{
      //     color: #ea3535;
      //   }
      //   .type3{
      //     color: #ea3535;
      //   }
    }
  }
}

@media screen and (max-width: 750px) {
  .company-card-con {
    width: 43.33vmin;
    border-radius: 2.67vw;

    img {
      width: 100%;
      height: auto;
      max-height: 23vw;
    }

    .con {
      box-sizing: border-box;
      padding: 2.67vmin 1.87vmin;
      height: 24.67vmin;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 3vw;
        margin-bottom: 1vw;
      }

      .time {
        height: 3vw;
        font-size: 2vw;
        line-height: 3vw;
      }

      .info {
        left: 2vw;
        right: 2vw;
        bottom: 4vw;

        .name {
          img {
            width: 3vw;
            height: 3vw;
            margin-right: 1vw;
          }

          span {
            font-size: 2vw;
            line-height: 3vw;
          }
        }

        .type {
          height: 3vw;
          font-size: 2.2vw;
          line-height: 3vw;
        }
      }
    }
  }
}
</style>
